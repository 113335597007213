<template>
  <div class="d-flex justify-center">
    <btn-link @click="handleRemoveResponsible">Remover</btn-link>
    <btn-link class="ml-3" @click="handleChangeData">Alterar Dados</btn-link>
  </div>
</template>
<script>
import BtnLink from "@/modules/core/views/components/BtnLink";

import { mapActions } from "vuex";

export default {
  name: "TableResponsibleActions",

  components: {
    BtnLink
  },

  methods: {
    ...mapActions("inviteUba", [
      "removeResponsibleFromNewInvite",
      "openDialogResponsibleToEdit"
    ]),

    handleRemoveResponsible() {
      const { data } = this.params;
      const message = this.$t("labels.confirm_action");
      this.$confirmDialog.open(message, () =>
        this.removeResponsibleFromNewInvite(data)
      );
    },

    handleChangeData() {
      const { data } = this.params;
      this.openDialogResponsibleToEdit(data);
    }
  }
};
</script>
<style lang="scss" scoped></style>
