<template>
  <div class="fill" style="height: 100%;">
    <v-data-table
      :items="items"
      :headers="headers"
      :items-per-page="1000"
      :no-data-text="
        $t('text.nenhum_registro_encontrado_por_tipo', { tipo: 'convite' })
      "
      :no-results-text="
        $t('text.nenhum_registro_encontrado_por_tipo', { tipo: 'convite' })
      "
      hide-default-footer
    >
      <!-- Productive unity -->
      <template v-slot:[`item.unidade_produtiva.nome_propriedade`]="{ item }">
        <link-detail-productive-unit-action
          :productiveUnit="item.unidade_produtiva"
        />
      </template>

      <!-- actions -->
      <template v-slot:[`item.id`]="{ item }">
        <table-uba-actions :mode="getMode()" :detail="item" />
      </template>

      <!-- Infinity load -->
      <template v-if="infinity" v-slot:[`body.append`]="{ headers }">
        <tr>
          <td :colspan="headers.length" style="height: 0;">
            <infinite-loading spinner="waveDots" @infinite="loadMore">
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import GridMixin from "@/modules/core/mixins/gridMixin";
import { TableUbaActionsOptions } from "@/modules/invite-uba/enums/table-uba-actions-options.enum";
import LinkDetailProductiveUnitAction from "@/modules/invite-uba/views/components/detail-production-unit/LinkDetailProductiveUnitAction";
import TableUBAActions from "@/modules/invite-uba/views/components/new-invite/TableUBAActions";

import { mapActions } from "vuex";

export default {
  name: "TableUBA",

  props: {
    items: {
      type: Array,
      required: true
    },

    hasSelectAction: {
      type: Boolean,
      required: false,
      default: () => false
    },

    hasRemoveAction: {
      type: Boolean,
      required: false,
      default: () => false
    },

    infinity: {
      type: Boolean,
      required: false,
      default: () => false
    },

    hasNext: {
      type: Boolean,
      required: false,
      default: () => true
    },

    showCategory: {
      type: Boolean,
      required: false,
      default: () => false
    },

    perPage: {
      type: Number,
      required: false,
      default: () => 15
    },

    fnLoadData: {
      type: Function,
      required: false
    }
  },

  mixins: [GridMixin],

  components: {
    LinkDetailProductiveUnitAction,
    TableUbaActions: TableUBAActions
  },

  data: () => ({
    TableUbaActionsOptions: TableUbaActionsOptions
  }),

  computed: {
    headers() {
      const firstChunk = [
        {
          text: "Nome Fantasia - UBA",
          value: "nome_fantasia",
          cellClass: ["text-uppercase", "font-weight-bold"]
        },
        {
          text: "Razão Social - UBA",
          value: "razao_social",
          cellClass: "text-uppercase"
        },
        {
          text: "CPF/CNPJ - UBA",
          value: "cpf_cnpj",
          width: 180
        },
        {
          text: "Município",
          value: "municipio",
          cellClass: "text-uppercase"
        },
        {
          text: "Dig. Extensão + GS1",
          value: "codigo_gs1_digito_extensao"
        },
        {
          text: "Unidade Produtiva",
          value: "unidade_produtiva.nome_propriedade",
          cellClass: "text-uppercase",
          align: "center"
        }
      ];

      const secondChunk = [
        {
          text: "Safra de Certificação",
          value: "safra_certificacao",
          align: "center"
        }
      ];

      const category = {
        text: "Categoria",
        value: "categoria",
        align: "center"
      };

      const baseHeaders = this.showCategory
        ? [...firstChunk, category, ...secondChunk]
        : [...firstChunk, ...secondChunk];

      if (this.hasSelectAction || this.hasRemoveAction) {
        const hasAllActions = this.hasSelectAction && this.hasRemoveAction;
        return [
          ...baseHeaders,
          {
            text: "",
            value: "id",
            sortable: false
          }
        ];
      }

      return baseHeaders;
    }
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),
    getMode() {
      if (this.hasSelectAction && this.hasRemoveAction) {
        return TableUbaActionsOptions.ALL;
      }

      if (this.hasSelectAction) {
        return TableUbaActionsOptions.SELECT;
      }

      if (this.hasRemoveAction) {
        return TableUbaActionsOptions.REMOVE;
      }
    },

    async loadMore($state) {
      if (!this.hasNext) {
        $state.complete();
        return;
      }

      try {
        await this.fnLoadData();
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      } finally {
        $state.loaded();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
