<template>
  <div>
    <tab-uba-wrapper>
      <v-alert class="mt-3" type="info">
        Não é possível efetivar o Convite caso a Unidade de Beneficiamento não
        possua ao menos 1(um) proprietário vinculado.
      </v-alert>

      <p class="section-subtitle">
        Revise se os dados do(s) proprietário(s) da Unidade de Beneficiamento
        estão corretos
      </p>

      <v-divider></v-divider>

      <div
        class="mt-3 mb-5"
        v-for="(processingUnit, index) in selectedProcessingUnits"
        :key="index"
      >
        <p class="section-subtitle primary-text--text">
          {{ index + 1 }}. Dados da UBA
        </p>

        <div class="fill" style="height: 120px">
          <table-uba :items="[processingUnit]" :showCategory="true" />
        </div>

        <p class="section-subtitle primary-text--text">
          {{ index + 1 }}. Dados do(s) Proprietário(s)
        </p>

        <div
          class="without-owner"
          v-if="!processingUnit.proprietarios.length"
          type="error"
        >
          <strong
            >Não existe(m) Proprietário(s) cadastrado(s) para esta Unidade de
            Beneficiamento. Entre em contato com a ABRAPA.</strong
          >
        </div>

        <div v-else>
          <owner-detail
            v-if="true"
            :owners="processingUnit.proprietarios"
            :socialName="processingUnit.razao_social"
          />
        </div>

        <v-divider
          class="my-5"
          v-if="index < selectedProcessingUnits.length - 1"
        />
      </div>
    </tab-uba-wrapper>
  </div>
</template>

<script>
import TabUBAWrapper from "@/modules/invite-uba/views/components/new-invite/TabUBAWrapper";
import TableUBA from "@/modules/invite-uba/views/components/new-invite/TableUBA";
import OwnerDetail from "@/modules/invite-uba/views/components/new-invite/OwnerDetail";

import { mapGetters } from "vuex";

export default {
  name: "StepReviewOwner",

  components: {
    TabUbaWrapper: TabUBAWrapper,
    TableUba: TableUBA,
    OwnerDetail
  },

  data: () => ({
    defaultEmptyData: "Não registrado"
  }),

  computed: {
    ...mapGetters("inviteUba", {
      selectedProcessingUnits: "getNewInviteSelectedProcessingUnits"
    })
  }
};
</script>

<style lang="scss" scoped>
.without-owner {
  border-radius: 6px;
  background-color: #f6f6f6;
  padding: 20px 10px;
  text-align: center;
}
</style>
