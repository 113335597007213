import { debounce, pickBy, identity } from "lodash";

export default {
  data: () => ({
    tableInfo: {
      page: 1,
      totalItems: 0,
      totalPerPage: 0,
      totalPages: 0,
      limit: 10
    },

    filters: {},

    perPageOptions: [
      { value: 10, text: "10 itens/página" },
      { value: 25, text: "25 itens/página" },
      { value: 50, text: "50 itens/página" },
      { value: 100, text: "100 itens/página" }
    ]
  }),

  created() {
    if (!this.updateData && typeof this.updateData !== "function") {
      throw new Error(
        "TableMixin's implementation MUST have updateData function"
      );
    }
    this.setFilterDebounced = debounce(async function(key, value) {
      this.setFilter(key, value);
    }, 800);
  },

  watch: {
    "tableInfo.limit": function() {
      this.tableInfo.page = 1;
      this.updateData();
    },

    "tableInfo.page": function() {
      this.updateData();
    }
  },

  methods: {
    setTableInfo(meta) {
      this.tableInfo = {
        ...this.tableInfo,
        ...meta
      };
    },

    setFilter(key, value) {
      this.filters[key] = value;
    },

    clearFilters() {
      this.filters = {};
    },

    applyFilters(newFilters) {
      this.filters = { ...newFilters };
    },

    getPayloadData() {
      return {
        ...pickBy(this.filters, identity),
        page: this.tableInfo.page,
        limit: this.tableInfo.limit
      };
    }
  }
};
