<template>
  <div class="d-flex justify-center">
    <btn-link
      v-if="modeSelected.selectBtnActive"
      @click="handleSelectItem"
      :disabled="disabledSelect"
      >{{ disabledSelect ? "Selecionado" : "Selecionar" }}</btn-link
    >
    <btn-link
      v-if="modeSelected.removeBtnActive"
      class="ml-3"
      @click="handleDeleteItem"
      >Remover</btn-link
    >
  </div>
</template>

<script>
import BtnLink from "@/modules/core/views/components/BtnLink";

import { mapActions, mapGetters } from "vuex";
import { TableUbaActionsOptions } from "@/modules/invite-uba/enums/table-uba-actions-options.enum";

export default {
  name: "TableUBAActions",

  props: {
    mode: {
      type: String,
      required: true
    },
    detail: {
      type: Object,
      required: true
    }
  },

  components: {
    BtnLink
  },

  data: () => ({
    modeOptions: {
      [TableUbaActionsOptions.SELECT]: {
        type: TableUbaActionsOptions.SELECT,
        selectBtnActive: true,
        removeBtnActive: false
      },
      [TableUbaActionsOptions.REMOVE]: {
        type: TableUbaActionsOptions.REMOVE,
        selectBtnActive: false,
        removeBtnActive: true
      },
      [TableUbaActionsOptions.ALL]: {
        type: TableUbaActionsOptions.ALL,
        selectBtnActive: true,
        deleteBtnActive: true
      }
    }
  }),

  computed: {
    ...mapGetters("inviteUba", {
      newInviteSelectedProcessingUnitsInDraftIds:
        "getNewInviteSelectedProcessingUnitsInDraftIds",
      newInviteSelectedProcessingUnitsIds:
        "getNewInviteSelectedProcessingUnitsIds"
    }),

    disabledSelect() {
      return this.isCurrentUBAInDraft();
    },

    modeSelected() {
      const mode = this.modeOptions[this.mode];
      if (!mode) {
        throw new Error("Mode selected invalid");
      }
      return mode;
    }
  },

  methods: {
    ...mapActions("inviteUba", [
      "selectProcessingUnitToInviteInDraft",
      "removeProcessingUnitToInvite"
    ]),

    isCurrentUBAInDraft() {
      if (!this.detail?.id) {
        return false;
      }

      const isInDraft = this.newInviteSelectedProcessingUnitsInDraftIds.includes(
        this.detail.id
      );

      const isSelected = this.newInviteSelectedProcessingUnitsIds.includes(
        this.detail.id
      );

      return isInDraft || isSelected;
    },

    handleSelectItem() {
      this.selectProcessingUnitToInviteInDraft(this.detail.id);
    },

    handleDeleteItem() {
      this.$confirmDialog.open(this.$t("labels.confirm_action"), () =>
        this.removeProcessingUnitToInvite(this.detail.id)
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
