<template>
  <div>
    <tab-uba-wrapper>
      <v-alert class="mt-3" type="info">
        Só podem ser convidadas Unidades de Beneficiamento de Algodão que se
        encontram Habilitadas e Ativas (fizeram atualização de cadastro, em SAI,
        nesta safra), ou seja, que já confirmaram seu cadastro para a safra
        vigente no sistema SAI.
      </v-alert>

      <p class="section-subtitle">
        1- Selecione a Unidade de Beneficiamento de Algodão (UBA) para ser
        convidada
      </p>

      <div class="mb-3">
        <v-btn color="primary" @click="openDialogSelectUBA"
          >Adicionar UBA</v-btn
        >
      </div>

      <v-divider></v-divider>

      <div v-if="newInviteSelectedProcessingUnitsLength">
        <p class="mt-3 section-subtitle">
          2 - Verifique os dados das Unidades de Beneficiamento do Algodão
          selecionadas
        </p>

        <div class="fill" style="height: 390px;">
          <selected-ubas :hasRemoveAction="true" />
        </div>
      </div>
    </tab-uba-wrapper>
    <dialog-select-uba ref="dialogSelectUBA" />

    <dialog-detail-production-unit ref="dialogDetailProductionUnit" />
  </div>
</template>

<script>
import TabUBAWrapper from "@/modules/invite-uba/views/components/new-invite/TabUBAWrapper";
import DialogSelectUBA from "@/modules/invite-uba/views/components/new-invite/DialogSelectUBA";
import SelectedUBAs from "@/modules/invite-uba/views/components/new-invite/SelectedUBAs";
import DialogDetailProductionUnit from "../detail-production-unit/DialogDetailProductionUnit";

import { mapGetters } from "vuex";

export default {
  name: "StepSelectUBA",

  components: {
    TabUbaWrapper: TabUBAWrapper,
    DialogSelectUba: DialogSelectUBA,
    SelectedUbas: SelectedUBAs,
    DialogDetailProductionUnit
  },

  computed: {
    ...mapGetters("inviteUba", {
      newInviteSelectedProcessingUnitsLength:
        "getNewInviteSelectedProcessingUnitsLength"
    })
  },

  methods: {
    openDialogSelectUBA() {
      if (!this.$refs?.dialogSelectUBA?.open) {
        throw new Error(
          "Failed to open dialog, missing $refs or function open"
        );
      }
      this.$refs.dialogSelectUBA.open();
    },
    openDialogDetailProductionUnit() {
      if (!this.$refs?.dialogDetailProductionUnit?.open) {
        throw new Error(
          "Failed to open dialog, missing $refs or function open"
        );
      }
      this.$refs.dialogDetailProductionUnit.open();
    }
  }
};
</script>

<style lang="scss" scoped></style>
