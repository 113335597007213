<template>
  <div>
    <v-dialog
      v-model="modalState"
      scrollable
      origin="center left"
      width="1400"
      :persistent="true"
      :retain-focus="false"
    >
      <v-card class="modal">
        <v-card-text>
          <h3 class="my-4 primary--text">
            Selecionar Unidade de Beneficiamento de Algodão
          </h3>

          <div>
            <v-row>
              <v-col cols="12">
                <v-alert class="mt-3" type="info">
                  Só podem ser convidadas Unidades de Beneficiamento de Algodão
                  que se encontram Habilitadas e Ativas (fizeram atualização de
                  cadastro, em SAI, nesta safra), ou seja, que já confirmaram
                  seu cadastro para a safra vigente no sistema SAI.
                </v-alert>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="10">
                <v-text-field
                  @input="debounceInput"
                  v-bind:value="filters.buscar"
                  dense
                  outlined
                  placeholder="Busque por Razão social, Nome Fantasia, CPF/CNPJ, Município ou Dígito Extensão + GS1."
                  prepend-inner-icon="mdi-magnify"
                  :hide-details="true"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="mt-3">
              <v-col cols="12">
                <v-menu
                  v-model="aditionalSearch"
                  offset-x
                  :close-on-content-click="false"
                  :nudge-width="200"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      text
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      >Outras opções de filtro</v-btn
                    >
                  </template>

                  <v-card>
                    <v-card-text class="pa-0 pt-2 px-3">
                      <v-autocomplete
                        @input="inputProductiveUnitChange"
                        :search-input.sync="productiveUnitsInput"
                        :items="productiveUnits"
                        :loading="loadingProductiveUnits"
                        :clearable="true"
                        placeholder="Busque por Unidade Produtiva"
                        no-data-text="Nenhuma unidade produtiva encontrada"
                        hide-no-data
                        item-text="nome_propriedade"
                        item-value="nome_propriedade"
                        no-filter
                        outlined
                      >
                      </v-autocomplete>
                      <v-checkbox
                        class="mt-0"
                        dense
                        v-model="filters.possui_unidade_produtiva"
                        label="Filtrar somente UBAs que pertençam à Unidades Produtivas"
                      ></v-checkbox>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end align-center">
                      <v-btn
                        text
                        class="mr-2 px-8"
                        @click="cancelAdvancedSearch"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        color="primary"
                        class="mr-2 px-8"
                        @click="searchProductiveUnit"
                        >Pesquisar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" style="height: 360px;">
                <h2 class="my-7 primary-text--text">
                  Unidades de Beneficiamento do Algodão encontradas
                </h2>
                <table-uba
                  ref="tableUba"
                  :items="items"
                  :hasSelectAction="true"
                  :infinity="true"
                  :fnLoadData="loadMoreProcessingUnits"
                  :perPage="limit"
                  :hasNext="next"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="mx-n1">
            <v-col cols="12" class="fill" style="height: 230px;overflow: auto;">
              <selected-ubas-list />
            </v-col>
          </v-row>
        </v-card-actions>

        <v-card-actions>
          <v-layout justify-end>
            <v-btn @click="handleClose" class="mr-5" color="#FFF">{{
              $t("buttons.cancel")
            }}</v-btn>

            <optional-tooltip
              :hide="processingUnitsInDraftLength > 0"
              text="Por favor, selecione uma Unidade de Beneficiamento de Algodão!"
            >
              <v-btn
                :disabled="!processingUnitsInDraftLength"
                color="primary"
                @click="handleConfirm"
                >{{ $t("buttons.confirm") }}</v-btn
              >
            </optional-tooltip>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableUBA from "@/modules/invite-uba/views/components/new-invite/TableUBA";
import SelectedUBAsList from "@/modules/invite-uba/views/components/new-invite/SelectedUBAsList";
import OptionalTooltip from "@/modules/core/views/components/OptionalTooltip";
import TableMixin from "@/modules/core/mixins/tableMixin";

import { debounce, pickBy, identity } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DialogSelectUBA",

  components: {
    TableUba: TableUBA,
    SelectedUbasList: SelectedUBAsList,
    OptionalTooltip
  },

  mixins: [TableMixin],

  data: () => ({
    items: [],
    page: 1,
    limit: 15,
    filters: {},
    next: true,

    productiveUnits: [],
    productiveUnitsInput: "",
    loadingProductiveUnits: false,
    searchModelProductiveUnits: undefined,

    modalState: false,
    aditionalSearch: false,
    filterOnlyUBAsRelatedToUP: false
  }),

  watch: {
    productiveUnitsInput(newVal) {
      this.debounceProductiveUnitsInput(newVal);
    }
  },

  computed: {
    ...mapGetters("inviteUba", {
      processingUnitsInDraftLength:
        "getNewInviteSelectedProcessingUnitsInDraftLength"
    })
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("inviteUba", [
      "fetchProcessingUnits",
      "clearProcessingUnitInDraftToInvite",
      "changeInviteProcessingUnitInDraftToSelecteds",
      "fetchProductiveUnitsWithoutGlobalLoading"
    ]),

    debounceInput: debounce(async function(value) {
      this.filters.buscar = value;
      this.clearTableItems();
      await this.initProcessingUnits();
    }, 800),

    debounceProductiveUnitsInput: debounce(async function(value) {
      await this.getProductiveUnitsByName(value);
    }, 500),

    async getProductiveUnitsByName(name) {
      if (!name || !name.length) {
        return;
      }
      try {
        this.loadingProductiveUnits = true;
        const response = await this.fetchProductiveUnitsWithoutGlobalLoading({
          nome: name
        });
        this.productiveUnits = response.results;
      } finally {
        this.loadingProductiveUnits = false;
      }
    },

    inputProductiveUnitChange(value) {
      this.filters.nome_unidade_produtiva = value;
    },

    clearFilters() {
      this.filters = {};
    },

    searchProductiveUnit() {
      this.aditionalSearch = false;
      this.clearTableItems();
      this.initProcessingUnits();
    },

    cancelAdvancedSearch() {
      this.filters = {
        buscar: this.filters.buscar
      };
      this.aditionalSearch = false;
    },

    clearTableItems() {
      this.page = 1;
      this.items = [];
    },

    async open() {
      this.clearTableItems();
      await this.initProcessingUnits();

      this.$nextTick(() => {
        this.modalState = true;
        this.clearFilters();
      });
    },

    handleClose() {
      this.clearProcessingUnitInDraftToInvite();
      this.modalState = false;
    },

    handleConfirm() {
      this.changeInviteProcessingUnitInDraftToSelecteds();
      this.modalState = false;
    },

    async initProcessingUnits() {
      const data = await this.getProcessingUnits();
      this.next = !!data?.next;
      this.items = data.results;
    },

    async callFetchProcessingUnits(payload) {
      try {
        return this.fetchProcessingUnits(payload);
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    async getProcessingUnits() {
      const payload = this.getPayloadData();
      return await this.callFetchProcessingUnits(payload);
    },

    updateData() {
      this.getProcessingUnits();
    },

    async loadMoreProcessingUnits(startRow, fnSuccess) {
      this.page++;
      const payload = this.getPayloadData();
      const data = await this.callFetchProcessingUnits(payload);
      this.items = [...this.items, ...data.results];
      this.next = !!data?.next;
    },

    getPayloadData() {
      const payload = {
        ...this.filters,
        page: this.page,
        limit: this.limit
      };
      return pickBy(payload, identity);
    }
  }
};
</script>

<style lang="scss" scoped></style>
