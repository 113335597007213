<template>
  <div class="fill fill-height">
    <table-responsibles
      :items="items"
      @removeResponsible="prepareRemoveResponsible($event)"
      @editResponsible="handleEditResponsible($event)"
    />
  </div>
</template>

<script>
import TableResponsibles from "@/modules/invite-uba/views/components/new-invite/TableResponsibles";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "SelectedResponsibles",

  components: {
    TableResponsibles
  },

  computed: {
    ...mapGetters("inviteUba", {
      items: "getNewInviteSelectedResponsibles"
    })
  },

  methods: {
    ...mapActions("inviteUba", [
      "removeResponsibleFromNewInvite",
      "addOrUpdateResponsibleToNewInvite"
    ]),

    prepareRemoveResponsible(responsible) {
      this.$confirmDialog.open(this.$t("labels.confirm_action"), () => {
        this.removeResponsibleFromNewInvite(responsible);
      });
    },

    handleEditResponsible(responsible) {
      this.addOrUpdateResponsibleToNewInvite(responsible);
    }
  }
};
</script>

<style lang="scss" scoped></style>
