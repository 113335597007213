import { AG_GRID_LOCALE } from "@/modules/core/locale/ag-grid";

export default {
  data: () => ({
    localeText: AG_GRID_LOCALE
  }),

  methods: {
    getStyleHeightByAmountOfItems(amount) {
      const height = 55 + 42 * (amount || 2);
      return `height: ${height}px`;
    }
  }
};
