<template>
  <div>
    <div v-if="hasProductiveUnit">
      <div class="d-flex justify-center">
        <btn-link @click="handleOpenDetail">{{ productiveUnitName }}</btn-link>
      </div>
      <dialog-detail-production-unit ref="dialogDetailProductionUnit" />
    </div>
    <p class="text-center" v-else>{{ $t("text.field_not_found") }}</p>
  </div>
</template>

<script>
import BtnLink from "@/modules/core/views/components/BtnLink";
import DialogDetailProductionUnit from "@/modules/invite-uba/views/components/detail-production-unit/DialogDetailProductionUnit";

export default {
  name: "LinkDetailProductiveUnitAction",

  components: {
    BtnLink,
    DialogDetailProductionUnit
  },

  props: {
    productiveUnit: {
      type: Object,
      required: false
    }
  },

  computed: {
    hasProductiveUnit() {
      return !!this.productiveUnit?.id;
    },

    productiveUnitName() {
      return this.productiveUnit?.nome_propriedade;
    }
  },

  methods: {
    handleOpenDetail() {
      if (!this.$refs?.dialogDetailProductionUnit?.open) {
        throw new Error(
          "Failed to open dialog, missing $refs or function open"
        );
      }
      const { id, safra_id } = this.productiveUnit;
      this.$refs.dialogDetailProductionUnit.open(id, safra_id);
    }
  }
};
</script>

<style lang="scss" scoped></style>
