<template>
  <div class="fill fill-height">
    <v-data-table
      :items="items"
      :headers="headers"
      :no-data-text="notFoundText"
      :no-results-text="notFoundText"
      hide-default-footer
      :items-per-page="1000"
    >
    </v-data-table>
  </div>
</template>

<script>
import GridMixin from "@/modules/core/mixins/gridMixin";

export default {
  name: "TablePresses",

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  mixins: [GridMixin],

  data: () => ({
    headers: [
      {
        text: "Número",
        value: "numero_prensa",
        cellClass: ["font-weight-bold"]
      },
      {
        text: "Modelo/Marca",
        value: "modelo_usina",
        cellClass: "text-uppercase"
      },
      {
        text: "Capacidade Fardos/Hora",
        value: "capacidade_fardos_hora",
        align: "center"
      },
      {
        text: "Número de Descaroçadores",
        value: "numero_descarocadores",
        align: "center"
      },
      {
        text: "Número Serras Descaroçador",
        value: "numero_serras_descarocador",
        align: "center"
      },
      {
        text: "Peso Médio do Fardo (Kg)",
        value: "peso_medio_fardo",
        align: "center"
      }
    ]
  }),

  computed: {
    notFoundText() {
      return this.$t("text.nenhum_registro_encontrado_por_tipo", {
        tipo: "registro"
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
