<template>
  <v-data-table
    :hide-default-footer="true"
    :headers="headers"
    :items="items"
    :items-per-page="1000"
    class="mb-10"
    :no-data-text="$t('text.nenhum_registro_selecionado_tabelas')"
  >
    <template v-slot:[`item.acao`]="{ item }">
      <v-row>
        <v-col cols="5"
          ><v-btn
            small
            text
            color="primary"
            @click="$emit('removeResponsible', item)"
            >Remover</v-btn
          ></v-col
        >
        <v-col cols="7"
          ><v-btn
            small
            text
            color="primary"
            @click="prepareEditResponsible(item)"
            >Editar dados</v-btn
          ></v-col
        >
      </v-row>

      <dialog-invite-user
        :dialogActivate="stateDialogInviteUser"
        @inviteUser="handleInviteUser"
        @onCloseDialog="handleOnCloseDialog"
      />
    </template>
  </v-data-table>
</template>

<script>
import TableResponsiblesActions from "@/modules/invite-uba/views/components/new-invite/TableResponsiblesActions";
import DialogInviteUser from "@/modules/invite/views/components/DialogInviteUser.vue";

export default {
  name: "TableResponsibles",

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  components: {
    DialogInviteUser
  },

  data: () => ({
    stateDialogInviteUser: {
      value: false,
      isEdit: true,
      initialData: {}
    }
  }),

  computed: {
    headers() {
      return [
        { text: "Nome", value: "nome", cellClass: "text-uppercase" },
        { text: "CPF", value: "cpf" },
        { text: "E-mail", value: "email", cellClass: "text-uppercase" },
        { text: "Telefone", value: "telefone" },
        { text: "Celular", value: "celular" },
        { text: "", value: "acao" }
      ];
    }
  },

  methods: {
    prepareEditResponsible(responsible) {
      this.stateDialogInviteUser.initialData = { ...responsible };
      this.stateDialogInviteUser.value = true;
    },

    async handleInviteUser(payload) {
      this.$emit("editResponsible", payload.data);
      this.stateDialogInviteUser.value = false;
    },

    handleOnCloseDialog() {
      this.stateDialogInviteUser.value = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
