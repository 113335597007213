<template>
  <div>
    <v-tabs :value="0" grow>
      <v-tab>ABR-UBA</v-tab>
    </v-tabs>
    <v-tabs-items :value="0">
      <v-tab-item><slot></slot></v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: "TabUBAWrapper"
};
</script>

<style lang="scss" scoped></style>
