<template>
  <div>
    <h2 class="my-7">Unidades de Beneficiamento do Algodão selecionadas</h2>

    <ol v-if="items.length">
      <li class="my-1" v-for="(item, index) in items" :key="index">
        <v-row>
          <v-col sm="7">
            <span>
              {{ item.razao_social }} | {{ item.cpf_cnpj }} |
              {{ item.codigo_gs1_digito_extensao }}
            </span>
          </v-col>
          <v-col sm="3">
            <btn-link @click="handleRemove(item)">Remover</btn-link>
          </v-col>
        </v-row>
      </li>
    </ol>

    <note v-else class="mt-4">
      Nenhuma uma Unidade de Beneficiamento de Algodão selecionada.
    </note>
  </div>
</template>

<script>
import BtnLink from "@/modules/core/views/components/BtnLink";
import Note from "@/modules/core/views/components/Note";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SelectedUBAsList",

  components: {
    BtnLink,
    Note
  },

  computed: {
    ...mapGetters("inviteUba", {
      items: "getNewInviteSelectedProcessingUnitsInDraft"
    })
  },

  methods: {
    ...mapActions("inviteUba", ["removeProcessingUnitInDraftToInvite"]),

    handleRemove(item) {
      this.removeProcessingUnitInDraftToInvite(item.id);
    }
  }
};
</script>

<style lang="scss" scoped></style>
