<template>
  <div class="fill fill-height">
    <table-uba
      :items="items"
      :hasRemoveAction="hasRemoveAction"
      :showCategory="showCategory"
    />
  </div>
</template>

<script>
import TableUBA from "@/modules/invite-uba/views/components/new-invite/TableUBA";

import { mapGetters } from "vuex";

export default {
  name: "SelectedUBAs",

  components: {
    TableUba: TableUBA
  },

  props: {
    hasRemoveAction: {
      type: Boolean,
      required: false,
      default: () => false
    },

    showCategory: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },

  computed: {
    ...mapGetters("inviteUba", {
      items: "getNewInviteSelectedProcessingUnits"
    })
  }
};
</script>

<style lang="scss" scoped></style>
