<template>
  <v-card elevation="0" color="gray-1">
    <v-card-text>
      <v-row>
        <v-col sm="3">
          <v-card elevation="0">
            <v-card-text>
              <div class="mb-2 d-flex flex-column">
                <p class="ma-0 secondary-text--text">
                  {{ getLabelNameByType(owners[0]) }}
                </p>
                <div v-if="!isPJ(owners[0])" style="display: flex;">
                  <p class="ma-0 primary-text--text text-uppercase">
                    {{ owners[0].nome || defaultEmptyData }}
                  </p>
                  <p
                    v-if="owners.length > 1"
                    class="ma-0 primary-text--text text-uppercase"
                    style="padding-left: 7px;"
                  >
                    E Outros
                  </p>
                </div>
              </div>
              <div v-if="isPJ(owners[0])" class="mb-2 d-flex flex-column">
                <p class="ma-0 secondary-text--text">
                  {{ $t("labels.corporate_name") }}
                </p>
                <div style="display: flex;">
                  <p class="ma-0 primary-text--text text-uppercase">
                    {{ socialName }}
                  </p>
                  <p
                    v-if="owners.length > 1"
                    class="ma-0 primary-text--text text-uppercase"
                    style="padding-left: 7px;"
                  >
                    E Outros
                  </p>
                </div>
              </div>
              <div class="mb-4 d-flex flex-column">
                <p class="ma-0 secondary-text--text">
                  {{ getLabelIdentifyByType(owners[0]) }}
                </p>
                <p class="ma-0 primary-text--text">
                  {{ owners[0].cpf_cnpj || defaultEmptyData }}
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
const OwnerTypes = {
  PF: "PF",
  PJ: "PJ"
};

export default {
  props: {
    owners: {
      type: Array,
      require: true
    },
    socialName: String
  },
  data: () => ({
    defaultEmptyData: "Não informado"
  }),
  methods: {
    isPJ(owner) {
      if (owner.cpf_cnpj.length != 11) {
        owner.tipo = OwnerTypes.PJ;
      }
      return owner.tipo?.toUpperCase() === OwnerTypes.PJ;
    },
    getLabelNameByType(owner) {
      if (this.isPJ(owner)) {
        return null;
      } else {
        return this.$t("labels.full_name");
      }
    },
    getLabelIdentifyByType(owner) {
      if (this.isPJ(owner)) {
        return this.$t("labels.cnpj");
      }

      return this.$t("labels.cpf");
    }
  }
};
</script>

<style lang="scss" scoped></style>
