import { render, staticRenderFns } from "./StepDefineResponsible.vue?vue&type=template&id=6597b4f2&scoped=true&"
import script from "./StepDefineResponsible.vue?vue&type=script&lang=js&"
export * from "./StepDefineResponsible.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6597b4f2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VAlert,VBtn})
